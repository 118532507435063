@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Futura';
  font-weight: 400; /* Normal weight */
  src: url('./fonts/futura_n4.woff2') format('woff2');
}

@font-face {
  font-family: 'Futura';
  font-weight: 600; /* Bold weight */
  src: url('./fonts/futura_n6.woff2') format('woff2');
}

@font-face {
  font-family: 'Tiemann';
  src: url('./fonts/tiemann.woff2') format('woff2');
}

@font-face {
  font-family: 'Righteous';
  src: url('./fonts/Righteous-Regular.ttf') format('truetype');
}